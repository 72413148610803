
// 解密只传type的
export const UrlDecodeType = {
    data() {
        return {
            queryType: '',//请求类型
        }
    },
    created() {
        let params = this.$route.query.key ? JSON.parse(this.$UrlEncode.decode(this.$route.query.key)) : '';
        this.queryType = params.type;
    },
}

// 解密只传id参数的
export const UrlDecodeId = {
    data() {
        return {
            queryId: '',//请求id
        }
    },
    created() {
        let params = this.$route.query.key ? JSON.parse(this.$UrlEncode.decode(this.$route.query.key)) : '';
        this.queryId = params.id
    },
}
// 解密传id参数和type的
export const UrlDecodeIdType = {
    data() {
        return {
            queryId: '',//请求id
            queryType: '',//请求类型
        }
    },
    created() {
        let params = this.$route.query.key ? JSON.parse(this.$UrlEncode.decode(this.$route.query.key)) : '';
        this.queryId = params.id
        this.queryType = params.type;
    },
}

// 解密传id参数和type的
export const UrlDecodeIdType1 = {
    data() {
        return {
            queryId: '',//请求id
            queryType: '',//请求类型
            originQueryType: '',//原请求类型
        }
    },
    created() {
        let params = this.$route.query.key ? JSON.parse(this.$UrlEncode.decode(this.$route.query.key)) : '';
        this.queryId = params.id
        this.queryType = params.type;
        this.originQueryType = params.originQueryType;
    },
}

