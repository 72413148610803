<template>
    <div class="ReturnOrderDetails">
        <el-page-header @back="$router.go(-1)" content="退货单详情"></el-page-header>
        <div class="f-w-b">
            <NavigationBar></NavigationBar>
            <div class="main">
                <div class="banner">
                    {{ enterpriseStateInfo.enterpriseName }}
                </div>
                <div class="boxShadow infoTable">
                    <div>
                        <div class="title">退货信息</div>
                        <div>
                            <div v-for="(item, index) of ReturnInfo" :key="index">
                                <div v-if="item.value" class="f-c-b titleItem">
                                    <span class="titval">{{ item.title }}</span>
                                    <div style="width: 50%" class="f-c"
                                        v-if="item.title == '关联单号：' && isShowReturnOrderBtn">
                                        <span class="mr-20">{{ item.value }}</span>
                                        <div @click="checkRelationBtn"
                                            style="height: 40px;width: 80px; background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);border-radius: 10px;color: #FFF;line-height: 40px;text-align: center;cursor: pointer;">
                                            查看</div>
                                    </div>
                                    <span class="value" v-else>{{ item.value }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="title mt-30">客户信息</div>
                        <div>
                            <div v-for="(item1, index1) of CustomerInfo" :key="index1">
                                <div v-if="item1.value" class="f-c-b titleItem">
                                    <span class="titval">{{ item1.title }}</span>
                                    <span class="value">{{ item1.value }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="title mt-30">商品明细</div>
                        <div>
                            <div class="f-w-b h-60 talTit">
                                <span>品名</span>
                                <span>编号</span>
                                <span>价格</span>
                                <span>数量</span>
                                <span>小计</span>
                                <span>备注</span>
                            </div>
                            <div v-if="OrderDetailsInfo.orderDetailsList && OrderDetailsInfo.orderDetailsList.length != 0">
                                <div class="talVal f-w-b" v-for="(item2, index2) of OrderDetailsInfo.orderDetailsList"
                                    :key="index2 + 2">
                                    <span class="f-c-c">{{ item2.customerUseName }}</span>
                                    <span class="f-c-c">{{ item2.customerUseNumber }}</span>
                                    <span class="f-c-c">{{ item2.unitPrice }}</span>
                                    <span class="f-c-c">{{ item2.quantity }}</span>
                                    <span class="f-c-c">{{ item2.amount }}</span>
                                    <div>{{ item2.note ? item2.note : '-' }}</div>
                                </div>
                            </div>
                            <div v-else class="f-c-c talVal2">
                                暂无数据
                            </div>

                        </div>
                        <div class="title mt-30">退货单备注</div>
                        <div class="modifyNote">
                            {{ OrderDetailsInfo.returnNote ? OrderDetailsInfo.returnNote : '无' }}
                        </div>
                        <div class="title mt-30">退货单操作备注</div>
                        <div class="modifyNote">
                            {{ OrderDetailsInfo.modifyNote ? OrderDetailsInfo.modifyNote : '无' }}
                        </div>
                    </div>
                </div>
                <div class="f-c-a mt-30"
                    v-if="OrderDetailsInfo.returnStatus == 'Returning' || OrderDetailsInfo.returnStatus == 'returning' && o_m == 'W'">
                    <div class="btn" @click="updateOrderReturnState"> 完成退货 </div>
                    <div class="btn" @click="cancelOrderReturn"> 取消退货 </div>
                    <!-- <el-button type="primary" round @click="updateOrderReturnState">完成退货</el-button> -->
                    <!-- <el-button type="primary" round @click="cancelOrderReturn">取消退货</el-button> -->
                </div>
            </div>
        </div>
        <!-- <TableList :dividerTitle="'退货信息'" :ItemInfoColor="'#888'" :list="ReturnInfo"
            :isShowReturnOrderBtn="isShowReturnOrderBtn" @checkRelationBtn="checkRelationBtn"></TableList>
        <TableList :dividerTitle="'客户信息'" :ItemInfoColor="'#888'" :list="CustomerInfo"></TableList>
        <TableList :dividerTitle="'商品明细'" :showList="false" :showTableList="true"
            :TableList="OrderDetailsInfo.orderDetailsList" :TableColumn="TableColumn">
        </TableList>
        <TableList :dividerTitle="'退货单备注：'" :showList="false" :ShowDisabledTextarea="true"
            :TextareaContent="OrderDetailsInfo.returnNote ? OrderDetailsInfo.returnNote : '无'">
        </TableList>
        <TableList :dividerTitle="'退货单操作备注：'" :showList="false" :ShowDisabledTextarea="true"
            :TextareaContent="OrderDetailsInfo.modifyNote ? OrderDetailsInfo.modifyNote : '无'">
        </TableList>
        <div class="Two-btn f-c-a"
            v-if="OrderDetailsInfo.returnStatus == 'Returning' || OrderDetailsInfo.returnStatus == 'returning' && o_m == 'W'">
            <el-button type="primary" round @click="updateOrderReturnState">完成退货</el-button>
            <el-button type="primary" round @click="cancelOrderReturn">取消退货</el-button>
        </div> -->
    </div>
</template>

<script>
import { TableColumn1 } from '@/utils/TableColumn'
import { UrlDecodeId } from '@/mixins/UrlDecodeMixin'
export default {
    name: 'ReturnOrderDetails',
    components: {
        NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue')
    },
    mixins: [UrlDecodeId],
    data() {
        return {
            OrderDetailsInfo: {},
            ReturnInfo: [],//退货信息
            CustomerInfo: [],//客户信息
            TableColumn: TableColumn1,
            isShowReturnOrderBtn: true,//是否显示关联单号按钮
            o_m: '',
            enterpriseStateInfo: {}
        }
    },
    created() {
        let pageLayerLength = this.$getStorage('pageLayer') ? this.$getStorage('pageLayer').length : 0;
        if (pageLayerLength == 4) {
            this.isShowReturnOrderBtn = false
        }
        this.findOrderReturnDetails();
        this.enterpriseStateInfo = this.$getStorage('enterpriseStateInfo');
        let { o_m } = this.$getStorage('enterpriseStateInfo').userPermission;
        this.o_m = o_m;
    },
    watch: {
        '$route.path': {
            handler(newVal) {
                let pageLayer = this.$getStorage('pageLayer') ? this.$getStorage('pageLayer') : 0;
                if (pageLayer.length == 3) {
                    pageLayer.push(newVal)
                    this.$setStorage('pageLayer', pageLayer)
                }
            },
            // 深度观察监听
            deep: true,
            immediate: true
        }
    },
    methods: {
        back() {
            let pageLayer = this.$getStorage('pageLayer');
            pageLayer.length > 0 ? this.$setStorage('pageLayer', pageLayer.slice(0, -1)) : pageLayer
            this.$common.DelayedBack(0);
        },
        // 用户搜索查看退货列表详细信息接口
        findOrderReturnDetails() {
            let that = this;
            that.$http.findOrderReturnDetails({
                lastModifyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                orderReturnId: parseInt(that.queryId)
            }).then((res) => {
                let { returnOrderNumber, returnStatus, deliverDate, deliveryDate, orderActive, orderCompleted, valuationCurrency, returnRealityAmount2,
                    freightCurrency, freightAmount, createDate, createName } = res.data,
                    returnStatusText = '';
                switch (returnStatus) {
                    case 'Returning':
                    case 'returning':
                        returnStatusText = '退货中'
                        break;
                    case 'Returned':
                    case 'returned':
                        returnStatusText = '已完成'
                        break;
                    case 'Canceled':
                    case 'canceled':
                        returnStatusText = '已取消'
                        break;
                }
                that.ReturnInfo = [{
                    "title": "退货单号：",
                    "value": returnOrderNumber
                }, {
                    "title": "运货状态",
                    "value": returnStatusText
                }, {
                    "title": "发货时间：",
                    "value": deliverDate
                }, {
                    "title": "收货时间：",
                    "value": deliveryDate
                }, {
                    "title": "关联单号：",
                    "value": orderActive.orderNumber || orderCompleted.orderNumber
                }, {
                    "title": "退货金额：",
                    "value": valuationCurrency + ' ' + returnRealityAmount2
                }, {
                    "title": "退货运费：",
                    "value": freightCurrency + ' ' + freightAmount.toFixed(2)
                }, {
                    "title": "创建时间：",
                    "value": createDate
                }, {
                    "title": "创建人",
                    "value": createName
                }];
                that.CustomerInfo = [{
                    "title": "客户名称",
                    "value": orderActive.customerName || orderCompleted.customerName
                }, {
                    "title": "联系人",
                    "value": orderActive.contact || orderCompleted.contact
                }, {
                    "title": "联系电话",
                    "value": orderActive.contactPhone || orderCompleted.contactPhone
                }, {
                    "title": "地址",
                    "value": (orderActive.customerCity || orderCompleted.customerCity) + ' ' + (orderActive.customerAddress || orderCompleted.customerAddress)
                }];
                console.log('orderDetailsList', res.data)
                that.OrderDetailsInfo = res.data
            })
        },
        // 查看关联单号
        checkRelationBtn() {
            let that = this,
                { orderId, returnType } = that.OrderDetailsInfo;
            this.$router.push({
                name: 'OrderDetails',
                query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        id: orderId, orderStatus: returnType
                    }))
                }

            })
        },
        // 完成退货
        updateOrderReturnState() {
            let that = this;
            that.$confirm('是否确认此次退货已完成？', '提示').then(() => {
                that.$http.updateOrderReturnState({
                    lastModifyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    orderReturnId: that.OrderDetailsInfo.orderReturnId
                }).then((res) => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        that.$common.DelayedBack();
                    }
                })
            }).catch(() => { })
        },
        // 取消订单退货接口
        cancelOrderReturn() {
            let that = this;
            that.$confirm('是否取消（作废）此次退货？', '提示').then(() => {
                that.$http.cancelOrderReturn({
                    lastModifyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    orderReturnId: that.OrderDetailsInfo.orderReturnId
                }).then((res) => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        that.$common.DelayedBack();
                    }
                })
            }).catch(() => { })
        },
    },
}

</script>
<style lang='less' scoped>
.ReturnOrderDetails {
    .main {
        width: 980px;

        .banner {
            background: url('../../../assets/supply/supplyBanner.png');
            height: 100px;
            color: #FFFFFF;
            font-size: 30px;
            line-height: 100px;
            text-align: center;
            margin-bottom: 40px;
        }



        .infoTable {
            width: 899px;
            padding: 40px;
            border-radius: 10px;

            .title {
                margin-bottom: 22px;
                border-left: 4px solid #0363FA;
                padding-left: 10px;
                font-size: 24px;
                font-weight: 500;
                color: #333333;
            }

            .titleItem {
                padding: 0 30px;
                width: 840px;
                height: 50px;
                border: 1px solid #f2f2f2;
                margin-bottom: 5px;

                .titval {
                    font-size: 16px;
                    color: #999;
                    font-weight: 400;
                    width: 50%;
                }

                .value {
                    font-size: 16px;
                    color: #333;
                    font-weight: 400;
                    width: 50%;
                }
            }

            .talTit {
                border-top: 1px solid #EDECEC;
                border-left: 1px solid #EDECEC;
                background: #F8F8F8;
                // border-radius: 5px 5px 0px 0;

                span {
                    width: calc(100% / 6 - 6px);
                    text-align: center;
                    line-height: 60px;
                    color: #333;
                    font-weight: 500;
                    font-size: 18px;
                    border-right: 1px solid #EDECEC;
                }
            }

            .talVal {
                background: #fff;
                border-left: 1px solid #EDEDED;
                border-bottom: 1px solid #EDEDED;

                span {
                    width: calc(100% / 6 - 6px);
                    text-align: center;
                    line-height: 60px;
                    color: #333;
                    font-weight: 400;
                    font-size: 16px;
                    border-right: 1px solid #EDEDED;
                }

                div {
                    width: calc(100% / 6 - 6px);
                    text-align: center;
                    color: #333;
                    font-weight: 400;
                    font-size: 16px;
                    border-right: 1px solid #EDEDED;
                    word-break: break-all;
                    word-wrap: break-word;
                    line-height: 25px;
                }
            }
            .talVal2 {
                background: #fff;
                border-left: 1px solid #EDEDED;
                border-right: 1px solid #EDEDED;
                border-bottom: 1px solid #EDEDED;
                height: 60px;
            }

            .modifyNote {
                padding: 30px;
                // height: 230px;
                background: #F3F8FE;
                border-radius: 5px;
                color: #666666;
                white-space: pre-line;
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
            }
        }

        .btn {
            width: 160px;
            height: 50px;
            background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
            border-radius: 10px;
            color: #FFF;
            font-size: 16px;
            line-height: 50px;
            text-align: center;
            cursor: pointer;
        }
    }
}
</style>
